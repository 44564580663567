var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title"),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("div", { staticClass: "main clearfix" }, [
            _c(
              "div",
              { staticClass: "btn-inner" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { float: "right", "margin-top": "-30px" },
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.addOrg("add")
                      }
                    }
                  },
                  [_vm._v(" 新增组织机构 ")]
                )
              ],
              1
            ),
            _c("div", { staticClass: "organization-mains" }, [
              _c(
                "div",
                { staticClass: "organization-main" },
                [
                  _c("div", { staticClass: "tree-header" }, [
                    _c("span", { staticClass: "tree-left" }, [
                      _c("span", { staticClass: "tree-span-name" }, [
                        _vm._v("机构名称")
                      ])
                    ]),
                    _c("span", { staticClass: "tree-right" }, [
                      _c("span", { staticClass: "tree-span-set" }, [
                        _vm._v("状态")
                      ]),
                      _c("span", { staticClass: "tree-span-set" }, [
                        _vm._v("设置人员")
                      ]),
                      _c("span", { staticClass: "tree-span-operation" }, [
                        _vm._v("操作")
                      ])
                    ])
                  ]),
                  _c("el-tree", {
                    staticClass: "menu-tree",
                    attrs: {
                      load: _vm.getAllList,
                      lazy: "",
                      "node-key": "id",
                      "expand-on-click-node": false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var node = ref.node
                          var data = ref.data
                          return _c(
                            "span",
                            { staticClass: "custom-tree-node" },
                            [
                              _c("span", { staticClass: "tree-left" }, [
                                _c("span", { staticClass: "tree-span-name" }, [
                                  _c(
                                    "span",
                                    { staticClass: "tree-span-name-text" },
                                    [_vm._v(_vm._s(data.orgName))]
                                  ),
                                  node.level < 5
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-plus-outline tree-span-name-icon",
                                        on: {
                                          click: function($event) {
                                            return _vm.addOrg(
                                              "add",
                                              data.orgCode,
                                              data.orgTypeCode,
                                              data.orgName,
                                              data.meStatus,
                                              data.id
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ])
                              ]),
                              _c("span", { staticClass: "tree-right" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "tree-span-set",
                                    staticStyle: {
                                      "margin-left": "0px",
                                      width: "28%"
                                    }
                                  },
                                  [
                                    data.status === "20"
                                      ? _c(
                                          "span",
                                          { staticClass: "tree-open" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  data.status === "20"
                                                    ? "启用"
                                                    : "禁用"
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "tree-close" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  data.status === "20"
                                                    ? "启用"
                                                    : "禁用"
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "tree-span-set",
                                    staticStyle: { width: "26%" }
                                  },
                                  [
                                    _vm._v("     "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-btn",
                                        attrs: { href: "javascript:" },
                                        on: {
                                          click: function($event) {
                                            return _vm.goSet(
                                              data.orgCode,
                                              data.orgName
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("设置人员")]
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "tree-span-operation" },
                                  [
                                    _vm._v("       "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-btn",
                                        attrs: { href: "javascript:" },
                                        on: {
                                          click: function($event) {
                                            return _vm.addOrg(
                                              "edit",
                                              data.orgCode,
                                              data.orgTypeCode,
                                              data.orgName,
                                              data.meStatus,
                                              data.id
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-btn danger",
                                        attrs: { href: "javascript:" },
                                        on: {
                                          click: function($event) {
                                            return _vm.delOrg(data.orgCode)
                                          }
                                        }
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ])
          ]),
          _c(
            "el-dialog",
            {
              attrs: { title: _vm.orgTitle, visible: _vm.dialog.org },
              on: {
                "update:visible": function($event) {
                  return _vm.$set(_vm.dialog, "org", $event)
                },
                close: _vm.closeDialog
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  attrs: {
                    "label-position": "right",
                    "label-width": "150px",
                    model: _vm.addForm,
                    rules: _vm.rules
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-row single" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-top": "18px" },
                          attrs: { label: "名称：", prop: "orgName" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.addForm.orgName,
                              callback: function($$v) {
                                _vm.$set(_vm.addForm, "orgName", $$v)
                              },
                              expression: "addForm.orgName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "组织机构类型：",
                            prop: "orgTypeCode"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择组织机构类型" },
                              model: {
                                value: _vm.addForm.orgTypeCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.addForm, "orgTypeCode", $$v)
                                },
                                expression: "addForm.orgTypeCode "
                              }
                            },
                            _vm._l(_vm.option, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "组织机构标签：",
                            prop: "orgTypeCode"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                multiple: "",
                                placeholder: "请选择组织机构标签"
                              },
                              model: {
                                value: _vm.addForm.tag,
                                callback: function($$v) {
                                  _vm.$set(_vm.addForm, "tag", $$v)
                                },
                                expression: "addForm.tag"
                              }
                            },
                            _vm._l(_vm.tagList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态：", prop: "status" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.addForm.meStatus,
                                callback: function($$v) {
                                  _vm.$set(_vm.addForm, "meStatus", $$v)
                                },
                                expression: "addForm.meStatus"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: "20" } }, [
                                _vm._v(" 启用 ")
                              ]),
                              _c("el-radio", { attrs: { label: "10" } }, [
                                _vm._v(" 禁用 ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialog.org = false
                        }
                      }
                    },
                    [_vm._v(" 取 消 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading.submit,
                          expression: "loading.submit"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("addForm")
                        }
                      }
                    },
                    [_vm._v(" 保存 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }