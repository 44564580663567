<!-- list -->
<template>
  <el-main>
    <page-title />
    <div class="page">
      <div class="main clearfix">
        <div class="btn-inner">
          <el-button type="primary" style="float: right; margin-top: -30px;" @click="addOrg('add')">
            新增组织机构
          </el-button>
        </div>
        <div class="organization-mains">
          <div class="organization-main">
            <div class="tree-header">
              <span class="tree-left">
                <span class="tree-span-name">机构名称</span>
              </span>
              <span class="tree-right">
                <span class="tree-span-set">状态</span>
                <span class="tree-span-set">设置人员</span>
                <span class="tree-span-operation">操作</span>
              </span>
            </div>
            <el-tree :load="getAllList" lazy class="menu-tree" node-key="id" :expand-on-click-node="false">
              <span slot-scope="{ node, data }" class="custom-tree-node">
                <span class="tree-left">
                  <span class="tree-span-name">
                    <span class="tree-span-name-text">{{ data.orgName }}</span>
                    <i v-if="node.level < 5" class="el-icon-circle-plus-outline tree-span-name-icon"
                       @click="addOrg('add',data.orgCode,data.orgTypeCode,data.orgName,data.meStatus,data.id)"
                    /></span>
                </span>
                <span class="tree-right">
                  <span class="tree-span-set" style="margin-left: 0px; width: 28%">
                    <span v-if="data.status==='20'" class="tree-open">
                      {{ data.status === '20' ? '启用' : '禁用' }}
                    </span>
                    <span v-else class="tree-close">
                      {{ data.status === '20' ? '启用' : '禁用' }}
                    </span>
                  </span>
                  <span class="tree-span-set" style="width: 26%">
                    &nbsp;&nbsp;&nbsp;
                    <a href="javascript:" class="text-btn" @click="goSet(data.orgCode, data.orgName)">设置人员</a>
                  </span>
                  <span class="tree-span-operation">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="javascript:" class="text-btn" @click="addOrg('edit',data.orgCode,data.orgTypeCode,data.orgName,data.meStatus,data.id)">编辑</a>
                    <a href="javascript:" class="text-btn danger" @click="delOrg(data.orgCode)">删除</a>
                  </span>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
      <el-dialog :title="orgTitle" :visible.sync="dialog.org" @close="closeDialog">
        <el-form ref="addForm" label-position="right" label-width="150px" :model="addForm" class="" :rules="rules" @submit.native.prevent="">
          <div class="form-row single">
            <el-form-item label="名称：" prop="orgName" style="margin-top: 18px">
              <el-input v-model="addForm.orgName" />
            </el-form-item>
            <el-form-item label="组织机构类型：" prop="orgTypeCode">
              <el-select v-model="addForm.orgTypeCode " placeholder="请选择组织机构类型">
                <el-option v-for="item in option" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="组织机构标签：" prop="orgTypeCode">
              <el-select v-model="addForm.tag" multiple placeholder="请选择组织机构标签">
                <el-option v-for="item in tagList" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="状态：" prop="status">
              <el-radio-group v-model="addForm.meStatus">
                <el-radio label="20">
                  启用
                </el-radio>
                <el-radio label="10">
                  禁用
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialog.org = false">
            取 消
          </el-button>
          <el-button v-loading="loading.submit" type="primary" @click="submitForm('addForm')">
            保存
          </el-button>
        </div>
      </el-dialog>
    </div>
  </el-main>
</template>
<script>
export default {
  data () {
    return {
      apiGroup: 'org',
      type: {
        show: true,
        required: false,
        list: []
      },
      tag: {
        show: true,
        required: false,
        list: []
      },
      orgList: [],
      orgTitle: '',
      option: [
        {
          value: 'company',
          label: '集团'
        }, {
          value: 'subsidiaries',
          label: '公司'
        }, {
          value: 'department',
          label: '部门'
        }, {
          value: 'group',
          label: '小组'
        }
      ],
      tagList: [
        { label: '普通', value: 'Normal' },
        { label: '企业', value: 'Customer' },
        { label: '基础', value: 'Base' }
      ],
      node: {},
      resolve: undefined,
      addForm: {
        type: '',
        meStatus: '20',
        parentOrgCode: '',
        orgName: '',
        parentId: '',
        tag: '' || ''
      },
      rules: {
        orgName: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          { max: 50, message: '名称最多允许输入50个字符', trigger: 'blur' }
        ],
        meStatus: [
          { required: true, message: '请选择状态', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择组织机构类型', trigger: 'change' }
        ],
        tag: [
          { required: true, message: '请选择组织机构标签', trigger: 'change' }
        ]
      },
      loading: {
        submit: false,
        tab: false
      },
      dialog: {
        org: false
      }
    }
  },
  methods: {
    findAuth () {
      const systemFeature = this._.find(this.$store.state.featureList, { code: 'org-platform' })
      if (systemFeature) {
        this.apiGroup = 'org'
      } else {
        this.apiGroup = 'orgCustomer'
      }
    },
    getAllList (node, resolve) {
      this.loading.tab = true
      this.node = node
      this.resolve = resolve
      this.node.childNodes = []
      if (node.level === 0) {
        this.api.system.org.list().then(result => {
          if (result.data.success === true) {
            this.loading.tab = false
            this.orgList = result.data.data
            console.log(result.data.data)
            if (resolve) {
              resolve(result.data.data)
            }
            console.log(resolve.data)
          } else {
            this.loading.tab = false
            if (resolve) {
              resolve([])
            }
          }
        }).catch(e => {
          this.loading.tab = false
          if (resolve) {
            resolve([])
          }
        })
      } else {
        this.api.system.org.list({ parentOrgCode: node.data.orgCode }).then(result => {
          if (result.data.success === true) {
            this.loading.tab = false
            if (resolve) {
              resolve(result.data.data)
            }
          } else {
            this.loading.tab = false
            if (resolve) {
              resolve([])
            }
          }
        }).catch(e => {
          this.loading.tab = false
          if (resolve) {
            resolve([])
          }
        })
      }
    },
    // 新增/修改组织机构 没有详情接口 只能从列表里带
    addOrg (type, orgCode, orgType, orgName, meStatus, parentId, tag) {
      this.dialog.org = true
      if (type === 'add') {
        this.orgTitle = '新增组织机构'
        if (orgCode) {
          this.addForm.parentId = parentId
          this.addForm.parentOrgCode = orgCode
        }
      } else {
        this.orgTitle = '修改组织机构'
        this.addForm.orgCode = orgCode
        this.addForm.orgName = orgName
        this.addForm.meStatus = meStatus
        if (orgType) {
          this.addForm.tag = orgType.split(',')
        } else {
          this.addForm.tag = ''
        }
      }
    },
    // 新增修改组织机构保存
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.orgTitle === '新增组织机构') {
            this.loading.submit = true
            let data = {}
            if (this.addForm.parentOrgCode) {
              data = {
                parentId: this.addForm.parentId,
                orgName: this.addForm.orgName,
                parentOrgCode: this.addForm.parentOrgCode,
                tag: this.addForm.tag.join(','),
                type: this.addForm.type,
                meStatus: this.addForm.meStatus
              }
            } else {
              data = {
                orgName: this.addForm.orgName,
                tag: this.addForm.tag.join(','),
                type: this.addForm.type,
                meStatus: this.addForm.meStatus
              }
            }
            this.api.system.org.add(data).then(result => {
              this.$message.success(result.data.message || '新增成功')
              this.loading.submit = false
              this.dialog.org = false
              this.getAllList(this.node, this.resolve)
            }).catch(e => {
              this.loading.submit = false
            })
          } else {
            this.loading.submit = true
            let tag = ''
            if (this.addForm.tag) {
              tag = this.addForm.tag.join(',')
            }
            const data = {
              parentId: this.addForm.parentId,
              orgName: this.addForm.orgName,
              orgCode: this.addForm.orgCode,
              tag: tag,
              type: this.addForm.type,
              meStatus: this.addForm.meStatus
            }
            this.api.system.org.update(data).then(result => {
              this.$message.success(result.data.message || '修改成功')
              this.loading.submit = false
              this.dialog.org = false
              this.getAllList(this.node, this.resolve)
            }).catch(e => {
              this.loading.submit = false
            })
          }
        } else {
          return false
        }
      })
    },
    // 弹层关闭数据清理
    closeDialog () {
      this.addForm.parentId = ''
      this.addForm.orgName = ''
      this.addForm.orgCode = ''
      this.addForm.type = ''
      this.addForm.meStatus = '20'
      this.$refs.addForm.resetFields()
    },
    // 删除菜单
    delOrg (orgCode, node) {
      this.$confirm('确认删除?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.system.org.delete(orgCode).then(result => {
          this.$message.success(result.data.message || '删除成功')
          this.getAllList(this.node, this.resolve)
        })
      }).catch(() => {})
    },
    // 去人员设置
    goSet (orgCode, orgName) {
      const data = {}
      data.orgCode = orgCode
      data.orgName = orgName
      this.$router.push({
        name: 'orgSetting', query: data
      })
    },
    getTag () {
      this.api.system.org.list().then(result => {
        this.tag.list = result.data.data
      })
    }
  }
}
</script>
<style></style>
